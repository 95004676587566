ol li {
    list-style: decimal;
    list-style-position: outside;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 0.5em;
    -webkit-margin-start: 1em;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 0px;
}

.reset-list ul li {
    list-style: circle;
    list-style-position: outside;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 0.5em;
    -webkit-margin-start: 1em;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 0px;
}